import { Toast } from 'bootstrap'

/**
 * Обработать promise в try-catch с передачей исключения в ToastError и вернуть результат либо null.
 */
export async function toastCatch(promise: () => Promise<any>) {
    try {
        return await promise()
    } catch (ex) {
        console.error(ex)
        ToastError(ex)
        return null
    }
}

// целиком из легаси
export function ToastGen(bodyText: string | false = false, headerText: false | string = false) {
    return {
        _bodyText: bodyText,
        _headerText: headerText,
        _bodyType: false,
        _headerType: false,
        _closing: true,
        _autohide: true,
        header(header) {
            this._headerText = header
            return this
        },
        body(body) {
            this._bodyText = body
            return this
        },
        headerClass(type) {
            this._headerType = type
            return this
        },
        bodyClass(type) {
            this._bodyType = type
            return this
        },
        closing(flag) {
            this._closing = flag
            return this
        },
        autohide(flag) {
            this._autohide = flag
            return this
        },
        create() {
            const toastEl = document.createElement('div')
            toastEl.className = `toast border-${this._bodyType}-subtle`

            if (this._headerText) {
                var colors = ''
                if (this._headerType) {
                    colors = `text-${this._headerType}-emphasis bg-${this._headerType} border-${this._headerType}`
                }

                const header = document.createElement('div')
                header.className = 'toast-header ' + colors
                header.innerHTML = `<strong class="me-auto">${this._headerText}</strong>`
                if (this._closing) {
                    header.innerHTML += `<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>`
                }
                toastEl.append(header)
            }

            if (this._bodyText) {
                var colors = ''
                if (this._bodyType) {
                    colors = `text-${this._bodyType}-emphasis bg-${this._bodyType}-subtle  border-${this._bodyType}-subtle`
                }

                if (this._headerText == false && this._closing) {
                    toastEl.className = 'toast align-items-center text-success-emphasis border-1 ' + colors
                    const div = document.createElement('div')
                    div.className = 'd-flex'

                    const body = document.createElement('div')
                    body.className = 'toast-body '
                    body.innerHTML = this._bodyText

                    div.append(body)
                    div.innerHTML += `<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>`
                    toastEl.append(div)
                } else {
                    const body = document.createElement('div')
                    body.className = 'toast-body ' + colors
                    body.innerHTML = this._bodyText

                    toastEl.append(body)
                }
            }

            const toastContainer = document.getElementById('toast_container')
            if (!toastContainer) {
                throw new Error('#toast_container not exists!')
            }

            toastContainer.append(toastEl)
            const toast = new Toast(toastEl, {
                autohide: this._autohide,
            })
            toast.show()

            return toastEl
        },
    }
}

export function ToastError(e) {
    if (e.data) e = e.data.message || e.data.error
    else if (e.response) e = e.response.data.error || e.response.data.message
    else if (e instanceof Error) e = e.message

    ToastGen(e, 'Ошибка!').headerClass('danger').bodyClass('danger').autohide(false).create()
}

export function ToastDanger(message) {
    ToastGen(message).bodyClass('danger').create()
}

export function ToastSuccess(message) {
    ToastGen(message).bodyClass('success').create()
}

export function ToastInfo(message) {
    ToastGen(message).bodyClass('info').create()
}

export function ToastWarning(message) {
    ToastGen(message).bodyClass('warning').create()
}

// Заворачиваю в объект чтоб больше походило к стилю апи
export default {
    gen: ToastGen,
    error: ToastError,
    success: ToastSuccess,
    info: ToastInfo,
    warning: ToastWarning,
    danger: ToastDanger
}
